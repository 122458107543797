:root {
  --blue: #457b9d;
  --light: #f1faee;
  --black: #2b2b2b;
}

* {
  color: var(--black);
  user-select: none !important;
  pointer-events: none !important;
  -webkit-tap-highlight-color: transparent !important;
}
html,
body {
  height: 100%;
}
#root {
  height: 100%;
}
.App {
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  align-content: center;
  height: 100%;
  pointer-events: all !important;
  outline: none;
  padding: 0;
  background: none;
  border: none;
  width: 100vw;
}

span {
  color: var(--blue);
}

.button {
  background-color: var(--blue);
  border: none;
  color: var(--light);
  font-weight: bold;
  font-size: 1.6rem;
  border-radius: 5px;
  padding: 20px;
  margin-top: 100px;
}

.cta {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.cta--error {
  transition: none;
}

.cta--hidden {
  opacity: 0;
  /* pointer-events: none; */
}

.filler {
  position: fixed;
  width: 100vw;
  height: 0vh;
  bottom: 0;
  background-color: var(--blue);
  transition: all 1000ms ease-out;
  pointer-events: none;
}

.filler-filled {
  height: 100%;
  transition: all 5000ms ease-out;
}

.filler--done {
  opacity: 0 !important;
  height: 100% !important;
  transition: all 1000ms ease-out !important;
}

.message {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100vw;
  font-size: 1.6rem;
  /* font-weight: bold; */
  color: var(--blue);
  margin-top: 20px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.message--finalizing {
  z-index: 100;
  height: 100%;
}
.message--finalizing p {
  color: var(--light) !important;
}

.message--held {
  opacity: 1;
}

.error,
.result {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: opacity 0.5s ease;
  opacity: 1;
  z-index: 100;
}

.error--hide,
.result--hide {
  opacity: 0;
}
